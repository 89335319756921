import { Typography, useMediaQuery } from "@mui/material";
import { Theme, styled, useTheme } from "@mui/material/styles";
import { WithStyles, withStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import { useEscapeKeyPress } from "@shared/hooks/keyboard";
import moment, { Moment } from "moment-timezone";
import { useCallback, useContext, useEffect, useState } from "react";
import { DateRangePicker, FocusedInputShape, OrientationShape } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { I18nContext, TranslationProps, withTranslations } from "shared/utilities/I18nContext";
import { isDayInTheFuture } from "shared/utilities/Time";
import { olive } from "shared/utilities/theme";
import Button from "./Button";

export type Props = WithStyles<typeof styles> &
  TranslationProps & {
    onChange(range: DateRange): void;
    startDate: Date | null;
    endDate: Date | null;
    isOpen?: boolean;
    withPortal?: boolean;
    appendToBody?: boolean;
    isOutsideRange?: (day: Moment) => boolean;
    okButtonTitle?: string;
    onClose: () => void;
  };

function DateRangeSelect({
  withPortal = false,
  appendToBody = false,
  onChange,
  onClose,
  isOpen,
  isOutsideRange = isDayInTheFuture,
  okButtonTitle,
  ...props
}: Props) {
  // Dependencies

  const i18n = useContext(I18nContext);
  const { translations: t } = i18n;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  // State

  const [focusedInput, setFocusedInput] = useState<FocusedInput>(null);
  const [startDate, setStartDate] = useState<Date | null>(props.startDate);
  const [endDate, setEndDate] = useState<Date | null>(props.endDate);

  // Computed state

  const orientation: OrientationShape = isDesktop ? "horizontal" : "vertical";

  // Effects

  useEffect(setFocusedInputOnOpen, [isOpen, focusedInput]);
  useEffect(resetStateIfNeeded, [isOpen, props.startDate, props.endDate]);
  useEscapeKeyPress(closeModal);

  return (
    <Typography component="div">
      <DateRangePicker
        onDatesChange={savePickedRangeToState}
        startDate={toMoment(startDate)}
        endDate={toMoment(endDate)}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        startDateId="startDate"
        endDateId="endDate"
        isOutsideRange={isOutsideRange}
        startDatePlaceholderText={t.dateRangeSelect.startDate}
        endDatePlaceholderText={t.dateRangeSelect.endDate}
        renderMonthText={useCallback(renderMonthText, [t.dateRangeSelect])}
        phrases={t.dateRangeSelect.phrases}
        initialVisibleMonth={initialVisibleMonth}
        minimumNights={0}
        orientation={orientation}
        withFullScreenPortal={!isDesktop}
        displayFormat={i18n.getDateFormatString()}
        readOnly
        withPortal={withPortal}
        appendToBody={appendToBody}
        calendarInfoPosition="after"
        renderCalendarInfo={() => renderActionButtons()}
        hideKeyboardShortcutsPanel
      />
    </Typography>
  );

  function renderActionButtons() {
    return (
      <ActionButtons>
        <Button variant="ghost" onClick={cancelEdit}>
          {t.common.cancelAction}
        </Button>

        <Button variant="primary" onClick={saveDateRange}>
          {okButtonTitle || t.common.okAction}
        </Button>
      </ActionButtons>
    );
  }

  function resetStateIfNeeded() {
    if (!isOpen) return;

    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }

  function setFocusedInputOnOpen() {
    if (isOpen && !focusedInput) {
      setFocusedInput("startDate");
    }
  }

  function renderMonthText(month: Moment) {
    return `${t.dateRangeSelect.toMonth(month.get("month"))} ${month.get("year")}`;
  }

  function cancelEdit(e: React.MouseEvent) {
    e.preventDefault;
    closeModal();
  }

  function saveDateRange(e: React.MouseEvent) {
    e.preventDefault;

    onChange({ startDate, endDate });
    closeModal();
  }

  function closeModal() {
    onClose();
    setFocusedInput(null);
  }

  function savePickedRangeToState({ startDate, endDate }: { startDate: Moment | null; endDate: Moment | null }) {
    setStartDate(toDate(startDate));
    setEndDate(toDate(endDate));
  }
}

function initialVisibleMonth() {
  return moment().subtract(1, "month");
}

function toDate(input: Moment | null): Date | null {
  if (!input) return null;

  const year = input.get("year");
  const month = input.get("month");
  const date = input.get("date");
  const unixTimestamp = Date.UTC(year, month, date);

  return new Date(unixTimestamp);
}

function toMoment(input: Date | null): Moment | null {
  return input ? moment.utc(input) : input;
}

export type DateRange = { startDate: Date | null; endDate: Date | null };

type FocusedInput = FocusedInputShape | null;

const ActionButtons = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingInline: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  gap: theme.spacing(1),
  position: "absolute",
  right: 0,
  bottom: theme.spacing(6),
  left: 0,

  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-end",
    bottom: 0,
  },
}));

const styles = (theme: Theme) =>
  createStyles({
    "@global": {
      ".CalendarDay__selected_span": {
        background: olive.light,
      },
      ".CalendarDay__selected_span:hover": {
        background: olive.light,
      },
      ".CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover": {
        background: olive.main,
      },
      ".CalendarDay__blocked_out_of_range": {
        background: theme.palette.grey[100],
      },
      ".DateInput_input__focused": {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
      ".DateInput_input, .CalendarMonth_caption, .CalendarDay": {
        color: "inherit",
        fontWeight: "inherit",
        fontFamily: "inherit",
      },
      ".DateInput_input__readOnly": {
        cursor: "default",
      },
      ".DateRangePicker_picker": {
        zIndex: 9999,
      },
      ".DayPicker": {
        paddingBottom: "50px",
      },
    },
  });

export default withTranslations(withStyles(styles)(DateRangeSelect));
