import { useEffect } from "react";

const shortcutsMap = Object.freeze({
  enter: (event: KeyboardEvent) => event.key === "Enter",
  escape: (event: KeyboardEvent) => event.key === "Escape",
  globalSearch: (event: KeyboardEvent) => event.metaKey && event.key === "k",
});

export const useKeyboardShortcut = (shortcut: keyof typeof shortcutsMap, action: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const shouldHandle = Object.keys(shortcutsMap).some(key => shortcutsMap[shortcut](event));

      if (shouldHandle) {
        event.preventDefault();
        action();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });
};

export const useEnterKeyPress = (action: () => void) => useKeyboardShortcut("enter", action);
export const useEscapeKeyPress = (action: () => void) => useKeyboardShortcut("escape", action);
